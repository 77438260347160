.toggleimg {
  opacity: 0;
  pointer-events: none;
}
.toggleclick {
  position: absolute;
  width: 300px;
  height: 300px;
  //background-color: rgba(0,0,0,0.2);
  pointer-events: auto !important;
  z-index: 9;
  &--1 {
    top: 117px;
    right: 154px;
  }
  &--2 {
    top: 494px;
    right: 154px;
  }
  &--3 {
    top: 866px;
    right: 137px;
  }
  &--page9 {
    top: 676px;
    right: 210px;
  }
}
