.p2 {
  background-color: #ffffff;
}
.menubt {
  position: absolute;
  width: 1600px;
  height: 112px;
  //background-color: rgba(0,0,0,0.2);
  pointer-events: auto !important;
  cursor: pointer;
  z-index: 9;
  &--1 {
    top: 737px;
    left: 438px;
  }
  &--2 {
    top: 871px;
    left: 438px;
  }
  &--3 {
    top: 1001px;
    left: 438px;
  }
  &--4 {
    top: 1136px;
    left: 438px;
  }
  &--5 {
    top: 1270px;
    left: 438px;
  }
  &--close {
    top: 74px;
    right: 75px;
    width: 244px;
    height: 244px;
  }
}
