@import "scss/vars";
* {
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4 {
  -webkit-font-smoothing: antialiased;
}

$bp-desktop: 1280px;
$bp-tablet: 768px;
$bp-mobile: 480px;

$breakpoints: (
        small : $bp-mobile,
        medium: $bp-tablet,
        large : $bp-desktop,
);
$body-font-sizes: (
        null: 10px,
        small: 10px,
        medium: 14px,
        large: 16px
);
@function calculateRem($size) {
  $remSize: $size / 16px;
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}
@mixin pxtorem($size) {
  font-size: calculateRem($size);
}
@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    @include pxtorem(nth($fs-font-size, 1));
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    @include pxtorem($fs-font-size);
  }
}
html {
  @include font-size($body-font-sizes);
}

p.mid {
  font-size: calculateRem(20px);
}
p {
  color: #2b292b;
  font-size: calculateRem(16px);
}
h3 {
  font-size: calculateRem(18px);
  color: #000000;
  font-weight: bold;
  margin: calculateRem(16px) 0;
}
b {
  font-weight: 700;
}
h1,h2,h3,h4 {
  font-family: BebasNeueBold;
}
h1 {
  font-size: 122px;
}
html, body {
  color: $col-black;
}

