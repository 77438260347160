@import "./scss/mixins";
.pagewrapper {
  position: absolute;
  z-index: 3;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 0;
  }
  img {
    position: absolute;
    top: 0;
  }
  /*   > div {
      width: 100%;
      height: 100%;
    }
   svg {
      width: auto;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }*/
}
