.navigation {
  position: absolute;
  z-index: 2;
  width: fit-content;
  height: 100px;
  background-color: firebrick;
  color: #ffffff;
  padding: 30px;
  a {
    color: #ffffff;
  }
}
#BT_HOME {
  cursor: pointer;
  pointer-events: auto;
}
.interactive {
  cursor: pointer;
  pointer-events: auto;
}
.navbg {
  background-color: #ffffff;
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.bt {
  &--blue {
    transition: 0.2s all;
    &:hover {
      [inkscape\:label=bg] {
        //fill: #a1c5f1 !important;
      }
    }
  }
}
