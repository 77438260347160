.tabclick {
  position: absolute;
  width: 730px;
  height: 229px;
  //background-color: rgba(0,0,0,0.2);
  pointer-events: auto !important;
  cursor: pointer;
  z-index: 9;
  &--1 {
    top: 219px;
    left: 1127px;
  }
  &--2 {
    top: 219px;
    right: 80px;
  }
}
.tabimage {
  opacity: 0;
  &--1 {
    opacity: 1;
  }
}
