@font-face {
  font-family: 'BebasNeueBold';
  src: url("fonts/BebasNeueBold.otf") format("opentype");
}
@font-face {
  font-family: 'Karbon-Bold';
  src: url("fonts/Karbon-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'Karbon-Light';
  src: url("fonts/Karbon-Light.otf") format("opentype");
}

@font-face {
  font-family: 'Karbon-Medium';
  src: url("fonts/Karbon-Medium.otf") format("opentype");
}

@font-face {
  font-family: 'Karbon-Semibold';
  src: url("fonts/Karbon-Semibold.otf") format("opentype");
}

@font-face {
  font-family: 'Karbon-SemiboldItalic';
  src: url("fonts/Karbon-SemiboldItalic.otf") format("opentype");
}
