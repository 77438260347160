@import "scss/reset";
@import "scss/font";
@import "scss/typeface";
@import "scss/vars";
@import "scss/mixins";
body, html {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  overscroll-behavior-y: none;
  overscroll-behavior-block: none;
  position: fixed;
  pointer-events: none;
}
body {
  background-color: #cccccc;
}
#root {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
 }
.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transform-origin: center;
  z-index: 2;
}
.debugoverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("assets/debug/11497713_DS_Implants_Primertaper_Sales_Presentation_IPAD_S7_SS-01.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}
.full {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
  background-image: url("assets/debug/11497713_DS_Implants_Primertaper_Sales_Presentation_IPAD_S7_SS-01.jpg");
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  background-image: url("assets/overlay.png");
  background-size: cover;
  background-repeat: repeat;
  pointer-events: none;
  z-index: 3;
}
.scalecontainer {
  width: 2732px;
  height: 2048px;
  transform-origin: center top;
  overflow: hidden;
}
.centercontainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
svg {
  //pointer-events: none;
}
