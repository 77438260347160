$col-yellow: #FFD100;
$col-dark: #000000;

/**
New palette
 */
$col-black: rgba(45, 45, 45, 1);

$col-brown-1: #EBEAE4;
$col-brown-2: #D9D6CB;
$col-brown-3: #B5B09E;

$col-orange-1: #EEC03F;
$col-orange-2: #DD7E29;

$col-blue-1: #3F98D8;
$col-blue-2: #004892;
$col-blue-3: #212857;
