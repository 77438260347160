.swipe {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  //background: #ededed;
  //background: rgba(0,0,0,0.6);
}
