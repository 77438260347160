@import "scss/vars";
.btclicks {
  position: absolute;
  top: 447px;
  left: 741px;
  pointer-events: auto !important;
}
.btlabels {
  position: absolute;
  top: -1111px;
  left: 0px;
  pointer-events: auto !important;
}
.btclick {
  position: absolute;
  width: 200px;
  height: 200px;
  //background-color: rgba(0,0,0,0.2);
  pointer-events: auto !important;
  z-index: 9;
  &--1 {
    top: 1000px;
    left: 236px;
  }
  &--2 {
    top: 1000px;
    left: 594px;
  }
  &--3 {
    top: 1000px;
    left: 797px;
  }
  &--4 {
    top: 1000px;
    left: 1001px;
  }
  &--5 {
    top: 1000px;
    left: 1318px;
  }
  &--6 {
    top: 1000px;
    left: 1523px;
  }
  &--7 {
    top: 1000px;
    left: 1730px;
  }
}
.p13label {
  position: absolute;
  color: $col-blue-3;
  font-family: Karbon-Bold;
  font-size: 40px;
  text-align: center;
  line-height: 1;
  transform: translateX(-50%);
  opacity: 0;
  white-space: nowrap;
  &--1 {
    top: 2316px;
    left: 334px;
  }
  &--2 {
    top: 2316px;
    left: 708px;
  }
  &--3 {
    top: 2316px;
    left: 700px;
  }
  &--3 {
    top: 2316px;
    left: 908px;
  }
  &--4 {
    top: 2316px;
    left: 1104px;
  }
  &--5 {
    top: 2316px;
    left: 1422px;
  }
  &--6 {
    top: 2316px;
    left: 1623px;
  }
  &--7 {
    top: 2316px;
    left: 1845px;
  }
}
